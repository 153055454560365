import React from "react"
import Seo from "components/seo"
import Layout from "layout"
const ContactPage = ({ location }) => {

  return (
    <Layout>
      <Seo title="Contact" />
      
      <div className="contact-form">
        <iframe title="typeform" id="typeform-full" width="100%" height="100%" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/NngpsMCX?typeform-medium=embed-snippet"></iframe> <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script>
      </div>
    </Layout>
  )
}

export default ContactPage
